/* Copyright 2024 "Holloway" Chew, Kean Ho <kean.ho.chew@zoralab.com>
 * Copyright 2024 ZORALab Enterprise <tech@zoralab.com>
 *
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not
 * use this file except in compliance with the License. You may obtain a copy of
 * the License at:
 *                 http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations under
 * the License.
 */
@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 100;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-Thin.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: italic;
	font-weight: 100;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-ThinItalic.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 200;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-ExtraLight.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: italic;
	font-weight: 200;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-ExtraLightItalic.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 300;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-Light.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: italic;
	font-weight: 300;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-LightItalic.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 400;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-Regular.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: italic;
	font-weight: 400;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-Italic.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 500;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-Medium.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: italic;
	font-weight: 500;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-MediumItalic.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 600;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-SemiBold.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: italic;
	font-weight: 600;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-SemiBoldItalic.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 700;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-Bold.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: italic;
	font-weight: 700;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-BoldItalic.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 800;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-ExtraBold.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: italic;
	font-weight: 800;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-ExtraBoldItalic.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 900;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-Black.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Sans";
	font-style: italic;
	font-weight: 900;
	src: local("Noto Sans"),
		url("/fonts/NotoSans-BlackItalic.ttf");
}

@font-face {
	font-display: swap;
	font-family: "Noto Color Emoji";
	src: local("Noto Color Emoji"),
		url("/fonts/NotoColorEmoji.ttf");
}
