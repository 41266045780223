/* Copyright 2024 "Holloway" Chew, Kean Ho <kean.ho.chew@zoralab.com>
 * Copyright 2024 ZORALab Enterprise <tech@zoralab.com>
 *
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not
 * use this file except in compliance with the License. You may obtain a copy of
 * the License at:
 *                 http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations under
 * the License.
 */
:root {
	/* foundational layout definitions */
	--html-scroll-behavior: smooth;
	--body-layouts: "lc0" auto
			"lf0" minmax(0, max-content)
			"lnt0" minmax(0, auto)
			"lnl0" minmax(0, auto)
			"lnr0" minmax(0, auto)
			/ 100%
	;
	--body-layouts-align-horizontal: stretch;
	--body-layouts-align-vertical: stretch;
	--body-layouts-gap-spacing: 0;

	--main-layout-tag: lc0;
	--main-min-width: 100%;
	--main-width: 100%;
	--main-max-width: 100%;

	--content-padding-top: 10rem;
	--content-padding-left: 5%;
	--content-padding-bottom: 10rem;
	--content-padding-right: 5%;
	--content-overflow: hidden;

	--footer-layout-tag: lf0;
	--footer-min-width: 100%;
	--footer-width: 100%;
	--footer-max-width: 100%;

	--footer-padding-top: 3.5rem;
	--footer-padding-left: var(--content-padding-left);
	--footer-padding-bottom: 3.5rem;
	--footer-padding-right: var(--content-padding-right);

	--leftnav-layout-tag: lnl0;
	--rightnav-layout-tag: lnr0;
	--topnav-layout-tag: lnt0;


	/* z-index layout definitions */
	--z-index-max: 2147483647;
	--z-index-max-overlay: 2147483646;
	--z-index-nav-top: 2147483645;
	--z-index-nav: 2147483644;
	--z-index-nav-overlay: 2147483643;
	--z-index-content-top: 2147483642;
	--z-index-content: 2147483641;
	--z-index-content-overlay: 2147483640;
	--z-index-hidden: 0;


	/* animation chronographic timing defintions */
	--timing-rapid: .2s;
	--timing-fast: .3s;
	--timing-normal: .4s;
	--timing-slow: .5s;
	--timing-slowest: .8s;


	/* default settings */
	--body-font-family: "Roboto", "Helvetica Neue", "sans-serif";
	--body-font-size: 1.8rem;
	--body-font-size-print: 11pt;
	--body-font-style: normal;
	--body-font-weight: normal;
	--body-letter-spacing: .12rem;
	--body-line-height: 2.5rem;
	--body-text-align: center;

	--body-color: var(--color-typography-800);
	--body-color-print: #000;
	--body-background: var(--color-primary-50);
	--body-background-print: #FFF;
	--font-color: var(--body-color);


	/* engineering required safety specifications
	 * IMPORTANT: DO NOT CHANGE WITHOUT SUPPORTIVE RATIONALE!
	 */
	--body-color-standard-red: #ff0000;
	--body-background-standard-red: #FFC4D0;
	--body-color-standard-yellow: #ff4c00;
	--body-background-standard-yellow: #FFFF99;
	--body-color-standard-green: #0CC707;
	--body-background-standard-green: #D0F3D0;


	/* typography definitions */
	--h1-font-family: var(--body-font-family);
	--h1-font-size: 7rem;
	--h1-font-style: normal;
	--h1-font-weight: 200;
	--h1-line-height: 8rem;
	--h1-letter-spacing: .1rem;
	--h1-margin: 2rem 0 0;
	--h1-padding: 0;
	--h1-text-decoration: none;
	--h1-text-align: left;
	--h1-word-wrap: break-word;
	--h1-color: var(--font-color);
	--h1-decoration-color: initial;
	--h1-border-bottom: none;

	--h2-font-family: var(--body-font-family);
	--h2-font-size: 3.5rem;
	--h2-font-style: normal;
	--h2-font-weight: 500;
	--h2-line-height: 5rem;
	--h2-letter-spacing: .35rem;
	--h2-margin: 12rem 0 0;
	--h2-padding: 0;
	--h2-text-decoration: none;
	--h2-text-align: left;
	--h2-word-wrap: break-word;
	--h2-color: var(--font-color);
	--h2-decoration-color: initial;
	--h2-border-bottom: none;

	--h3-font-family: var(--body-font-family);
	--h3-font-size: 3rem;
	--h3-font-style: normal;
	--h3-font-weight: 500;
	--h3-line-height: 4rem;
	--h3-letter-spacing: .35rem;
	--h3-margin: 7rem 0 0;
	--h3-padding: 0;
	--h3-text-decoration: none;
	--h3-text-align: left;
	--h3-word-wrap: break-word;
	--h3-color: var(--font-color);
	--h3-decoration-color: initial;
	--h3-border-bottom: none;

	--h4-font-family: var(--body-font-family);
	--h4-font-size: 2.5rem;
	--h4-font-style: normal;
	--h4-font-weight: 500;
	--h4-line-height: var(--h4-font-size);
	--h4-letter-spacing: -.08rem;
	--h4-margin: 4.5rem 0 0;
	--h4-padding: 0;
	--h4-text-decoration: underline;
	--h4-text-align: left;
	--h4-word-wrap: normal;
	--h4-color: var(--font-color);
	--h4-decoration-color: initial;
	--h4-border-bottom: none;

	--h5-font-family: var(--body-font-family);
	--h5-font-size: 2rem;
	--h5-font-style: normal;
	--h5-font-weight: 500;
	--h5-line-height: var(--h5-font-size);
	--h5-letter-spacing: -.05rem;
	--h5-margin: 4.5rem 0 0;
	--h5-padding: 0;
	--h5-text-decoration: none;
	--h5-text-align: left;
	--h5-word-wrap: normal;
	--h5-color: var(--font-color);
	--h5-decoration-color: initial;
	--h5-border-bottom: none;
	--h5-border-bottom-inverted: none;

	--h6-font-family: var(--body-font-family);
	--h6-font-size: 2rem;
	--h6-font-style: italic;
	--h6-font-weight: 500;
	--h6-line-height: var(--h6-font-size);
	--h6-letter-spacing: 0;
	--h6-margin: 4.5rem 0 0;
	--h6-padding: 0;
	--h6-text-decoration: none;
	--h6-text-align: left;
	--h6-word-wrap: normal;
	--h6-color: var(--font-color);
	--h6-decoration-color: initial;
	--h6-border-bottom: none;

	--p-font-family: var(--body-font-family);
	--p-font-size: var(--body-font-size);
	--p-font-style: var(--body-font-style);
	--p-line-height: var(--body-line-height);
	--p-letter-spacing: var(--body-letter-spacing);
	--p-text-decoration: none;
	--p-text-align: left;
	--p-word-wrap: normal;
	--p-margin: 0;
	--p-padding: 1rem 0;
	--p-color: var(--font-color);
	--p-decoration-color: initial;

	--a-display: inline-block;
	--a-margin: initial;
	--a-padding: 0 .15rem;
	--a-min-width: initial;
	--a-width: max-content;
	--a-max-width: 100%;
	--a-min-height: initial;
	--a-height: initial;
	--a-max-height: initial;
	--a-border: initial;
	--a-border-top: none;
	--a-border-left: none;
	--a-border-bottom: 1px solid var(--a-color);
	--a-border-right: none;
	--a-border-radius: 0;
	--a-font-size: var(--body-font-size);
	--a-font-family: var(--body-font-family);
	--a-font-weight: initial;
	--a-text-decoration: none;
	--a-text-transform: normal;
	--a-overflow-wrap: break-word;
	--a-overflow-wrap-print: break-word;
	--a-letter-spacing: initial;
	--a-line-height: 2.2rem;
	--a-text-align: initial;
	--a-color: var(--body-color);
	--a-color-focus: var(--color-contrast-200);
	--a-color-print: var(--body-color-print);
	--a-text-decoration-color: var(--a-color);
	--a-background: transparent;
	--a-background-focus: transparent;
	--a-background-print: transparent;
	--a-filter: drop-shadow(0 0 0 transparent);
	--a-filter-focus: drop-shadow(0 0 .75 var(--body-color));
	--a-filter-active: none;
	--a-transition: var(--timing-rapid);
	--a-transform-focus: scale(1.03);
	--a-transform-active: scale(0.98);

	--pre-display: block;
	--pre-box-sizing: border-box;
	--pre-margin: 2rem auto;
	--pre-padding: 2rem;
	--pre-overflow: auto auto;
	--pre-border: .1rem solid var(--pre-color);
	--pre-border-radius: .5rem;
	--pre-min-width: initial;
	--pre-width: 100%;
	--pre-max-width: 100%;
	--pre-min-height: initial;
	--pre-height: initial;
	--pre-max-height: initial;
	--pre-font-size: var(--body-font-size);
	--pre-font-family: monospace;
	--pre-text-decoration: normal;
	--pre-word-break: keep-all;
	--pre-letter-spacing: 0;
	--pre-line-height: var(--body-line-height);
	--pre-text-align: justify;
	--pre-white-space: pre;
	--pre-color: var(--body-color);
	--pre-color-print: var(--body-color-print);
	--pre-background: var(--body-background);
	--pre-background-print: var(--body-background-print);
	--pre-transition: var(--timing-rapid);


	/* color system */
	--color-primary-50: #F7FBFF;
	--color-primary-100: #D0E6FF;
	--color-primary-200: #95C6FF;
	--color-primary-300: #6DB1FF;
	--color-primary-400: #469CFF;
	--color-primary-500: #1F87FF;
	--color-primary-600: #0073f7;
	--color-primary-700: #0057BF;
	--color-primary-800: #003C81;
	--color-primary-900: #002146;
	--color-primary-950: #000E1F;

	--color-dark-primary-50: #000E1F;
	--color-dark-primary-100: #002146;
	--color-dark-primary-200: #003C81;
	--color-dark-primary-300: #0057BF;
	--color-dark-primary-400: #0073f7;
	--color-dark-primary-500: #1F87FF;
	--color-dark-primary-600: #469CFF;
	--color-dark-primary-700: #6DB1FF;
	--color-dark-primary-800: #95C6FF;
	--color-dark-primary-900: #D0E6FF;
	--color-dark-primary-950: #F7FBFF;

	--color-contrast-50: #FFEA00;
	--color-contrast-100: #FFDD00;
	--color-contrast-200: #FFD000;
	--color-contrast-300: #FFC300;
	--color-contrast-400: #FFB700;
	--color-contrast-500: #FFAA00;
	--color-contrast-600: #FFA200;
	--color-contrast-700: #FF9500;
	--color-contrast-800: #FF8800;
	--color-contrast-900: #FF7B00;
	--color-contrast-950: #FFD207;

	--color-dark-contrast-50: #FFD207;
	--color-dark-contrast-100: #FF7B00;
	--color-dark-contrast-200: #FF8800;
	--color-dark-contrast-300: #FF9500;
	--color-dark-contrast-400: #FFA200;
	--color-dark-contrast-500: #FFAA00;
	--color-dark-contrast-600: #FFB700;
	--color-dark-contrast-700: #FFC300;
	--color-dark-contrast-800: #FFD000;
	--color-dark-contrast-900: #FFDD00;
	--color-dark-contrast-950: #FFEA00;

	--color-typography-50: #F5F5F5;
	--color-typography-100: #D8D8D8;
	--color-typography-200: #BBBBBB;
	--color-typography-300: #A7A7A7;
	--color-typography-400: #939393;
	--color-typography-500: #8A8A8A;
	--color-typography-600: #767676;
	--color-typography-700: #4F4F4F;
	--color-typography-800: #313131;
	--color-typography-900: #141414;
	--color-typography-950: #0A0A0A;

	--color-dark-typography-50: #0A0A0A;
	--color-dark-typography-100: #141414;
	--color-dark-typography-200: #313131;
	--color-dark-typography-300: #4F4F4F;
	--color-dark-typography-400: #767676;
	--color-dark-typography-500: #8A8A8A;
	--color-dark-typography-600: #939393;
	--color-dark-typography-700: #A7A7A7;
	--color-dark-typography-800: #BBBBBB;
	--color-dark-typography-900: #D8D8D8;
	--color-dark-typography-950: #F5F5F5;


	/* flexible grid layout system */
	--grid-width: 100%;
	--grid-justify-content: space-evenly;
	--grid-align-content: center;
	--grid-align-items: center;
	--grid-flex-wrap: wrap;
	--grid-flex-direction: row;
	--grid-gap: 3rem;

	--grid-column-display: block;
	--grid-column-width: initial;
	--grid-column-max-width: 100%;
	--grid-column-margin: 0 auto auto;
	--grid-column-padding: 0;
	--grid-column-visibility: visible;
	--grid-column-base: 10;
	--grid-column-multiplier: 1;
	--grid-column-flex-basis: auto;
	--grid-column-flex-shrink: 1;


	/* picture & img */
	--picture-display: block;
	--picture-margin: 2rem auto;
	--picture-padding: 0;
	--picture-min-width: initial;
	--picture-width: auto;
	--picture-max-width: 100%;
	--picture-min-height: initial;
	--picture-height: auto;
	--picture-max-height: initial;
	--picture-object-fit: cover;
	--picture-user-select: none;
	--picture-user-drag: none;
}
